import vBrightNs1 from "../images/v-bright-flower-fresh-3000-g.webp"
import vBrightNs2 from "../images/v-bright-flower-fresh-900-g.webp"
import vBrightNs3 from "../images/v-bright-flower-fresh-350-g.webp"
import vBrightNs4 from "../images/v-bright-flower-fresh-150-g.webp"
import vBrightNs5 from "../images/v-bright-pro-active-oxygen-1000-g.webp"
// import vBrightNs6 from "../images/v-bright-pro-active-oxygen-450-g.webp"
import vBrightNs7 from "../images/v-bright-pro-active-oxygen-100-g.webp"

export default [
  {
    id: 0,
    rec: "1",
    img: vBrightNs1,
    alt: "วี-ไบรท์ ฟลาวเวอร์ เฟรช ขนาด 3,000 กรัม",
    title: "วี-ไบรท์ ฟลาวเวอร์ เฟรช",
    content: "ขนาด 3,000 กรัม",
    status: "2",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 6 ถุง",
    price:"ราคาขายปลีกต่อถุง 135 บาท"
  },
  {
    id: 1,
    rec: "0",
    img: vBrightNs2,
    alt: "วี-ไบรท์ ฟลาวเวอร์ เฟรช ขนาด 900 กรัม",
    title: "วี-ไบรท์ ฟลาวเวอร์ เฟรช",
    content: "ขนาด 900 กรัม",
    status: "2",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 12 ถุง",
    price:"ราคาขายปลีกต่อถุง 45 บาท"
  },
  {
    id: 2,
    rec: "0",
    img: vBrightNs3,
    alt: "วี-ไบรท์ ฟลาวเวอร์ เฟรช ขนาด 350 กรัม",
    title: "วี-ไบรท์ ฟลาวเวอร์ เฟรช",
    content: "ขนาด 350 กรัม",
    status: "2",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 36 ถุง",
    price:"ราคาขายปลีกต่อถุง 20 บาท"
  },
  {
    id: 3,
    rec: "0",
    img: vBrightNs4,
    alt: "วี-ไบรท์ ฟลาวเวอร์ เฟรช ขนาด 150 กรัม",
    title: "วี-ไบรท์ ฟลาวเวอร์ เฟรช",
    content: "ขนาด 150 กรัม",
    status: "2",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 72 ถุง",
    price:"ราคาขายปลีกต่อถุง 10 บาท"
  },
  {
    id: 4,
    rec: "1",
    img: vBrightNs5,
    alt: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน ขนาด 1,000 กรัม",
    title: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน",
    content: "ขนาด 1,000 กรัม",
    status: "1",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 12 ถุง",
    price:"ราคาขายปลีกต่อถุง 69 บาท"
  },
  // {
    // id: 5,
    // rec: "0",
    // img: vBrightNs6,
    // alt: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน ขนาด 450 กรัม",
    // title: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน",
    // content: "ขนาด 450 กรัม",
    // status: "1",
    // type: "1",
    // total:"จำนวนบรรจุต่อลัง 12 ถุง",
    // price:"ราคาขายปลีกต่อถุง 55 บาท"
  // },
  {
    id: 5,
    rec: "0",
    img: vBrightNs7,
    alt: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน ขนาด 100 กรัม",
    title: "วี-ไบรท์ โปรแอคทิฟ ออกซิเจน",
    content: "ขนาด 100 กรัม",
    status: "1",
    type: "1",
    total:"จำนวนบรรจุต่อลัง 96 ถุง",
    price:"ราคาขายปลีกต่อถุง 10 บาท"
  },
]