import React from "react"
import PropTypes from "prop-types"
import recommendImg from "../../images/recommend.webp"
import { ProductsListStyle } from "./style"

const ProductsList = ({ recommend, img, alt, title, content, onClick }) => {
  return (
      <ProductsListStyle
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
      >
        <div className="img-products text-center">
          <img src={img} alt={alt} loading="lazy" />
          {recommend === 1 ? (
            <img
              className="recommend-style"
              src={recommendImg}
              alt="recommend"
              loading="lazy"
            />
          ) : (
            ""
          )}
        </div>
        <div className="card-body products-body text-center">
          <h4
            color="heading"
            className="card-title products-title mt-2"
            role="presentation"
          >
            {title}
          </h4>
          <p
            color="text"
            fontSize={2}
            className="card-text products-paragraph text-success"
          >
            {content}
          </p>
        </div>
      </ProductsListStyle>
  )
}
ProductsList.propTypes = {
  recommend: PropTypes.number,
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  onClick: PropTypes.func,
}
ProductsList.defaultProps = {
  recommend: 0,
}

export default ProductsList
