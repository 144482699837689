import React from "react"
import "./style.scss"

const TextArea = ({
  label,
  value,
  name,
  onChange,
  haveError,
  touched,
  desc,
  errors,
  detail,
  className,
  placeholder,
}) => {
  const showFeedback = (!!haveError && value.length > 2) || touched

  return (
    <div>
      <label htmlFor="fname">{label}</label>
      <textarea
        className={
          `input-default mt-2 ${
            showFeedback ? (errors ? "is-invalid input-err" : "") : ""
          }` + className
        }
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      {desc ? <p className="text-description m-0">{desc}</p> : ""}
      {showFeedback === true ? (
        <p className={`errText text-left m-0`}>
          {errors === "phone must be a valid phone number."
            ? "Invalid Phone Number!"
            : errors}
        </p>
      ) : (
        ""
      )}
      {detail && <p className="text-secondary pt-1">{detail}</p>}
    </div>
  )
}

export default TextArea
