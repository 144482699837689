import React from "react"
import "./style.scss"

const ButtonGroupToggle = ({ className, data ,handleRadio,valueName}) => {
  return (
    <div
      className={`button-group-toggle-style ${className}`}
      data-toggle="buttons"
    >
      {data.map((loan, key) => {
        const isCurrent = valueName === loan.id
        return (
          <div key={key.toString()} style={{marginRight:10}}>
            <label className={isCurrent ? "btn --selected" : "btn"}>
              <input
                type="radio"
                name="options"
                id={loan.id}
                value={loan.name}
                onChange={handleRadio}
              />
              {loan.name}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default ButtonGroupToggle
