import React from "react"

import "./style.scss"

const ContactUs = ({ otp }) => {
  return (
    <div className="px-3 mb-5">
        <div className="row">
          {otp.map(item => (
            <div className="col-xl-3 col-6" key={item.id}>
              <div className="icon-circle mx-auto">{item.icon}</div>
              <p className="text-secondary small m-0 mt-3">{item.name}</p>
              <p className="text-dark font-weight-500 m-0 mt-2">
                {item.content}
              </p>
            </div>
          ))}
        </div>
    </div>
  )
}

export default ContactUs
