import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "rc-dialog/assets/index.css"
import recommend from "../../images/recommend.webp"
import ButtonRounded from "../ButtonRounded"
import { ProductsDetailStyle } from "./style"
import { FaAngleRight, FaAngleLeft } from "react-icons/fa"

const ProductsDetail = ({
  visible,
  onClose,
  onClick,
  otp,
  nextData,
  prevData,
  onClickNext,
  onClickPrev,
  onClickOut,
  onKeyPress,
}) => {
  return (
    <ProductsDetailStyle
      className={`modal fade ${visible === true ? "show d-block" : ""}`}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      onClick={onClose}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div
          className="modal-content foo"
          onClick={onClickOut}
          aria-hidden="true"
        >
          <div className="modal-header border-bottom-0 py-0">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-3 pt-0 py-md-5">
            <div className="row align-items-center">
              <div className="col-6 col-md-2 pl-md-3 pr-md-3 order-2 order-md-1">
                {prevData.length > 0 &&
                prevData[0].id !== otp[0].id &&
                prevData.length > 0
                  ? prevData.map((item, index) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className="prev d-flex align-items-center"
                        onKeyDown={onClick}
                        onClick={onClickPrev}
                        key={index}
                      >
                        <FaAngleLeft className="arrow" />
                        <div>
                          <img
                            className="d-block mx-auto"
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                          />
                          <span>สินค้าต่อไป</span>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
              <div className="col-md-8 order-0 order-md-2">
                {otp.map(item => (
                  <div key={item.id}>
                    <div className="row">
                      <div className="col-md-5 col-12">
                        <div className="col-center">
                          <span className="recommend-style-detail">
                            {item.rec === "1" ? (
                              <img
                                src={recommend}
                                alt="recommend"
                                loading="lazy"
                              />
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="px-0 px-md-3 mx-auto">
                            <img
                              className="mx-auto product-thumb img-thumbnail border-0"
                              src={item.img}
                              alt={`v-bright ${item.title}`}
                              loading="lazy"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-7 col-12">
                        <div>
                          <h3 className="text-center text-md-left title-model">
                            {item.title}
                          </h3>
                          <p className="small text-success mt-1 font-weight-500 text-center text-md-left">
                            {item.content}
                          </p>
                          <div className="d-flex">
                            {item.type === "1" ? (
                              <div className="mb-2 mx-auto mx-md-0">
                                {item.status === "1" ? (
                                  <>
                                    <p className="small text-info mb-0">
                                      ด้วยพลัง "โปรแอคทิฟ ออกซิเจน"
                                    </p>
                                    <p className="small text-muted mb-1">
                                      ขจัดคราบฝังลึกได้เร็วและหมดจด
                                    </p>
                                    <li className="small text-muted">
                                      ผสมสารให้ความขาว ทำให้ผ้าสว่าง สดใส
                                    </li>
                                    <li className="small text-muted">
                                      ไม่ผสมผงแป้ง ไม่เหม็นแม้แช่ผ้าค้างคืน
                                    </li>
                                    <li className="small text-muted">
                                      ฟองน้อย ล้างออกง่าย ไม่กัดสีผ้า
                                    </li>
                                    <li className="small text-muted">
                                      ไม่ทิ้งคราบบนเนื้อผ้า
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <p className="small text-pink mb-0">
                                      ด้วยกลิ่น "ฟลาวเวอร์ เฟรช"
                                    </p>
                                    <p className="small text-muted mb-1">
                                      จึงหอมสดชื่นทุกครั้งที่ซัก
                                    </p>
                                    <li className="small text-muted">
                                      ผสมสารให้ความขาว ทำให้ผ้าสว่าง สดใส
                                    </li>
                                    <li className="small text-muted">
                                      ไม่ผสมผงแป้ง ไม่เหม็นแม้แช่ผ้าค้างคืน
                                    </li>
                                    <li className="small text-muted">
                                      ล้างออกง่าย ไม่กัดสีผ้าแม้ซักหลายครั้ง
                                    </li>
                                    <li className="small text-muted">
                                      ไม่ทิ้งคราบบนเนื้อผ้า
                                    </li>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="mb-2 mx-auto mx-md-0">
                                {item.status === "1" ? (
                                  <>
                                    <p className="small text-info mb-1">
                                      สะอาดใส ไร้กลิ่นคาว ไร้สารตกค้าง
                                    </p>
                                    <li className="small small text-muted">
                                      ขจัดคราบไขมันได้สะอาดหมดจด
                                    </li>
                                    <li className="small text-muted">
                                      ไม่มีกลิ่นติดจานแม้แต่ภาชนะพลาสติก
                                    </li>
                                    <li className="small text-muted">
                                      กลิ่นมะนาวหอมสดชื่น
                                    </li>
                                    <li className="small text-muted">
                                      ผสมน้ำมันมะพร้าว ช่วยถนอมมือ
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <p className="small text-info mb-1">
                                      สะอาดใส ไร้กลิ่นคาว ไร้สารตกค้าง
                                    </p>
                                    <li className="small text-muted">
                                      ขจัดคราบไขมันได้สะอาดหมดจด
                                    </li>
                                    <li className="small text-muted">
                                      ไม่มีกลิ่นติดจานแม้แต่ภาชนะพลาสติก
                                    </li>
                                    <li className="small text-muted">
                                      กลิ่นเมล่อนหอมสดชื่น
                                    </li>
                                    <li className="small text-muted">
                                      ผสมน้ำมันมะพร้าว ช่วยถนอมมือ
                                    </li>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="small mt-3 mt-md-2 text-center text-md-left">
                            <p className="small mb-0">{item.total}</p>
                            <p>{item.price}</p>
                          </div>
                          <div className="text-center text-md-left pb-3 pb-md-0">
                            <form action="https://line.me/ti/p/~@vgroup.th">
                              <ButtonRounded
                                className="font-size-18 "
                                onClick={onClick}
                              >
                                ซื้อเลย!
                              </ButtonRounded>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-6 col-md-2 px-md-0 order-2 order-md-3">
                {nextData.length > 0
                  ? nextData.map((item, index) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className="next d-flex align-items-center"
                        onKeyDown={onClick}
                        onClick={onClickNext}
                        key={index}
                      >
                        <div>
                          <img
                            className="d-block mx-auto"
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                          />
                          <span className="d-block">สินค้าก่อนหน้า</span>
                        </div>
                        <FaAngleRight className="arrow" />
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProductsDetailStyle>
  )
}

export default ProductsDetail