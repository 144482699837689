import styled from "styled-components"

export const ProductsListStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  min-width: 0px;
  border-image: initial;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 0px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  &:hover {
    box-shadow: 0px 3px 6px #e0e6d5;
    border-radius: 0.25rem;
  }
  .img-products {
    box-sizing: border-box;
    min-width: 0px;
  }
  .products-body {
    box-sizing: border-box;
    min-width: 0px;
    a {
      &:hover {
        color: rgb(25, 25, 27);
        text-decoration: none;
      }
    }
  }
  .products-title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    margin-bottom: 14px;

    @media (min-width: 768px) {
      font-size: 21px;
    }
  }
  .products-paragraph {
    font-weight: 300;
    letter-spacing: -0.66px;
    color: rgba(25, 25, 27, 0.75);
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.75;
  }

  .recommend-style {
    position: absolute;
    top: 0;
    right: 0;
  }
`
