import React from "react"
import PropTypes from "prop-types"
import { GoogleMapStyle } from "./style"

const GoogleMap = ({ className }) => {
  return (
    <GoogleMapStyle className={className}>
      <iframe
        title="Chinatown Salaya"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7749.555721815348!2d100.29966407642857!3d13.792260580791286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29478e5757e21%3A0x245eef28abd794a6!2z4LmE4LiK4LiZ4LmI4Liy4LiX4Liy4Lin4LiZ4LmMIOC4qOC4suC4peC4suC4ouC4sg!5e0!3m2!1sth!2sth!4v1649667119688!5m2!1sth!2sth"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        aria-hidden="false"
      />
    </GoogleMapStyle>
  )
}

GoogleMap.propTypes = {
  className: PropTypes.string,
}
GoogleMap.defaultProps = {
  className: "",
}

export default GoogleMap
