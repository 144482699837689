import React from "react"
import "./style.scss"

const SelectDefault = ({
  className,
  value,
  id,
  name,
  form,
  option,
  onChange,
  onBlur,
}) => {
  return (
    <div className={`select-default-style px-2 px-sm-0 ${className}`}>
      <select
        value={value}
        id={id}
        name={name}
        form={form}
        onChange={onChange}
        onBlur={onBlur}
      >
        {option.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectDefault
