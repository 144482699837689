import component1 from "../images/ns-v-bright-component.webp"
import component2 from "../images/v-wash-plus-component.webp"
import product1 from "../images/ns-v-bright-product.webp"
import product2 from "../images/v-wash-plus-product.webp"

export default [
  {
    id: 1,
    bg: component1,
    name: "พร้อมสู้หน้าฝน แม้แดดจะหาย ก็ไร้กลิ่นอับ",
    img: product1,
    alt: "ผงซักฟอก",
  },
  {
    id: 2,
    bg: component2,
    name: "ล้างคราบมันได้หมดจด ไร้กลิ่นคาว ไร้สารตกค้าง และยังถนอมมือ",
    img: product2,
    alt: "น้ำยาล้างจาน",
  },
]
