import styled from "styled-components"

export const ProductsDetailStyle = styled.div`
  &.modal:after {
    content: "";
    display: block;
    background: #000000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1;
  }
  &.modal.show .modal-dialog {
    z-index: 2;
  }

  .title-model {
    font-size: 18px;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  .next,
  .prev {
    text-align: center;
    color: #8b8b8b;
    font-weight: 500;
    justify-content: center;
    img {
      width: 35px;

      @media (min-width: 768px) {
        width: 50px;
      }
    }
  }

  .arrow {
    font-size: 24px;
  }

  .product-thumb {
    width: 150px;

    @media (min-width: 768px) {
      width: 100%;
    }
  }

  .recommend-style-detail {
    position: absolute;
    height: 20px;
    z-index: 2;
    align-self: flex-end;
    top: 25px;

    img {
      width: 30px;
    }

    @media (min-width: 768px) {
      height: 20px;
      top: 0;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
    background-color: transparent;
  }
`
