import React, { useState } from "react"
import {
  SEO,
  ProductsListDefault,
  ProductsDetailDefault,
  HeaderSlideDefault,
  GoogleMapDefault,
  TextAreaDefault,
  TextFieldDefault,
  ButtonGroupToggleDefault,
  ButtonRoundedDefault,
  SlideDefault,
  SelectDefault,
  ContactUsDefault,
  Layout,
} from "../components"
import {
  dataContactUs,
  dataImg,
  dataProduct,
  dataType,
  dataPowder,
  dataSolution,
  dataListPowder,
  dataListSolution,
} from "../data"
import { Formik } from "formik"
import * as Yup from "yup"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 3000,
  fadeIn: false,
  autoplay: true,
  pauseOnHover: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const Home = ({ data }) => {
  const [selectId, setSelectId] = useState("1")
  const [selectType, setSelectType] = useState("0")
  const [showDialog, setShowDialog] = useState(false)
  const [dataList, setDataList] = useState(dataListPowder)
  const [slideId, setSlideId] = useState([])
  const [slideNext, setSlideNext] = useState([])
  const [slidePrev, setSlidePrev] = useState([])

  const onChangeSelectId = function (e) {
    if (e === "1") {
      setSelectId(e)
      setSelectType("0")
      setDataList(dataListPowder)
    } else {
      setSelectId(e)
      setSelectType("0")
      setDataList(dataListSolution)
    }
  }

  const onChangeSelectType = function (e) {
    if (selectId === "1") {
      if (e === "0") {
        setDataList(dataListPowder)
        setSelectType(e)
      } else {
        let obj = dataListPowder.filter(o => o.status === e)
        setDataList(obj)
        setSelectType(e)
      }
    } else {
      if (e === "0") {
        setDataList(dataListSolution)
        setSelectType(e)
      } else {
        let obj = dataListSolution.filter(o => o.status === e)
        setDataList(obj)
        setSelectType(e)
      }
    }
  }

  const onClickNext = value => {
    onChangeShowDialog(value[0].id)
  }

  const onClickPrev = value => {
    onChangeShowDialog(value[0].id)
  }

  const onChangeShowDialog = value => {
    let filteredArray =
      selectId === "1"
        ? selectType === "0"
          ? dataListPowder.filter(x => x.id === value)
          : selectType === "1"
          ? dataList.filter(x => x.id === value)
          : dataList.filter(x => x.id === value)
        : selectType === "0"
        ? dataListSolution.filter(x => x.id === value)
        : selectType === "1"
        ? dataList.filter(x => x.id === value)
        : dataList.filter(x => x.id === value)

    let nextId = filteredArray[0].id + 1
    let prevId = filteredArray[0].id !== 0 ? filteredArray[0].id - 1 : 0

    let nextData =
      selectId === "1"
        ? selectType === "0"
          ? dataListPowder.filter(x => x.id === nextId)
          : selectType === "1"
          ? dataList.filter(x => x.id === nextId)
          : dataList.filter(x => x.id === nextId)
        : // else
        selectType === "0"
        ? dataListSolution.filter(x => x.id === nextId)
        : selectType === "1"
        ? dataList.filter(x => x.id === nextId)
        : dataList.filter(x => x.id === nextId)

    let prevData =
      selectId === "1"
        ? selectType === "0"
          ? dataListPowder.filter(x => x.id === prevId)
          : selectType === "1"
          ? dataList.filter(x => x.id === prevId)
          : dataList.filter(x => x.id === prevId)
        : selectType === "0"
        ? dataListSolution.filter(x => x.id === prevId)
        : selectType === "1"
        ? dataList.filter(x => x.id === prevId)
        : dataList.filter(x => x.id === prevId)

    if (showDialog === false) {
      setShowDialog(true)
      setSlideId(filteredArray)
      setSlideNext(nextData)
      setSlidePrev(prevData)
    } else {
      setSlideId(filteredArray)
      setSlideNext(nextData)
      setSlidePrev(prevData)
    }
  }
  return (
    <Layout>
      <SEO />
      <div className="header-logo">
        <Img
          fluid={data.logo.childImageSharp.fluid}
          alt="line"
          loading="lazy"
        />
      </div>
      <HeaderSlideDefault dataProduct={dataProduct} settings={settings} />

      <div className="bg-detail px-3 px-xl-5 pb-5 ">
        <div className="container mx-auto">
          <h2 className="text-center mt-5 mt-md-3 mb-5">วิสัยทัศน์</h2>
          <p className="text-secondary text-vision-size">
            ด้วยความมุ่งมั่นตั้งใจของผู้ก่อตั้งบริษัทฯ
            ที่อยากจะให้ผู้บริโภคได้มีโอกาสเข้าถึงสินค้าอุปโภคที่มีคุณภาพสูงในราคาย่อมเยา
            เราจึงสร้างสรรค์สินค้าผงซักฟอกภายใต้แบรนด์ วี-ไบรท์ ในปีพ.ศ. 2557
            และน้ำยาล้างจานภายใต้แบรนด์ วี-วอช พลัส ในปีพ.ศ. 2561
            พร้อมมอบประสบการณ์ใหม่ที่น่าประทับใจ
            สร้างรอยยิ้มและความพึงพอใจสูงสุดให้กับลูกค้าทุกระดับ
            ทำให้งานบ้านของคุณพ่อบ้าน แม่บ้าน
            กลายเป็นงานเบาๆที่ไม่น่าเบื่ออีกต่อไป
          </p>
        </div>
        <div className="container">
          <div className="row mx-auto align-items-center">
            <div className="col-md-12 col-lg-6">
              <div className="text-md-center">
                <Img
                  fluid={data.imageOne.childImageSharp.fluid}
                  alt="ผลิตภัณฑ์สำหรับทำความสะอาดเสื้อผ้า"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <h2 className="text-md-center">
                ผลิตภัณฑ์สำหรับทำความสะอาดเสื้อผ้า
              </h2>
              <div className="mx-auto mt-xl-5 mt-3">
                <Img
                  fluid={data.imageTwo.childImageSharp.fluid}
                  alt="ขจัดคราบ ไม่ทิ้งคราม ปราบกลิ่นอับ"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 mx-auto align-items-center">
            <div className="col-md-12 col-lg-6 order-lg-2">
              <div className="text-md-center">
                <Img
                  fluid={data.imageThree.childImageSharp.fluid}
                  alt="น้ำยาล้างจาน"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 text-md-left order-lg-1">
              <div>
                <h2 className="px-xl-4 mt-0">
                  ผลิตภัณฑ์สำหรับทำความสะอาด เครื่องใช้ในครัวเรือน
                </h2>
                <div className="mx-auto mt-xl-5 mt-3">
                  <Img
                    fluid={data.imageFour.childImageSharp.fluid}
                    alt="ผลิตภัณฑ์สำหรับทำความสะอาด เครื่องใช้ในครัวเรือน"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideDefault
        className="slick-dots-bottom"
        settings={settings}
        dataImg={dataImg}
      />

      <div className="mt-5">
        <div className="container mx-auto">
          <h2 className="text-center">PRODUCTS</h2>
          <div className="col-center align-items-center mt-5 mb-3 mb-sm-5 px-sm-0 px-2">
            <ButtonGroupToggleDefault
              data={dataType}
              handleRadio={e => onChangeSelectId(e.target.id)}
              valueName={selectId}
            />
          </div>

          {selectId === "1" ? (
            <SelectDefault
              option={dataPowder}
              value={selectType}
              onChange={e => onChangeSelectType(e.target.value)}
            />
          ) : (
            <SelectDefault
              option={dataSolution}
              value={selectType}
              onChange={e => onChangeSelectType(e.target.value)}
            />
          )}
          <div className="row row-cols-1 row-cols-md-3">
            {dataList.map(item => (
              <div
                className="col-xl-4 col-12 mb-4 card-deck mx-0"
                key={item.id}
              >
                <ProductsListDefault
                  recommend={+item.rec}
                  img={item.img}
                  key={item.id}
                  title={item.title}
                  alt={item.alt}
                  content={item.content}
                  onClick={() => onChangeShowDialog(item.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <ProductsDetailDefault
        visible={showDialog}
        onClose={() => setShowDialog(showDialog => !showDialog)}
        otp={slideId}
        prevData={slidePrev}
        nextData={slideNext}
        onClickNext={() => onClickNext(slideNext)}
        onClickPrev={() => onClickPrev(slidePrev)}
        onClickOut={e => e.stopPropagation()}
      />

      <div className="my-5 from-input">
        <div className="row mx-auto align-items-center">
          <div className="col-md-12 col-lg-6 px-0">
            <div>
              <Img
                fluid={data.industrial.childImageSharp.fluid}
                alt="industrial"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 px-0 text-center pb-5 pb-lg-0">
            <h2 className="mt-5 mt-lg-0">มาเป็นส่วนหนึ่งของเรา</h2>
            <div className="px-lg-5 px-3 mt-4">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  message: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Please input your name!"),
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Please input your e-mail!"),
                  message: Yup.string().required("Please input your message!"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2))
                    setSubmitting(false)
                  }, 400)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextFieldDefault
                      type="text"
                      name="name"
                      haveError={touched.name}
                      placeholder="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      errors={errors.name}
                      touched={touched.name}
                    />

                    <TextFieldDefault
                      type="text"
                      name="email"
                      haveError={touched.email}
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      errors={errors.email}
                      touched={touched.email}
                    />

                    <TextAreaDefault
                      name="message"
                      haveError={touched.message}
                      placeholder="Enter your message…"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                      errors={errors.message}
                      touched={touched.message}
                    />

                    <ButtonRoundedDefault
                      className="width-185 font-size-18 mt-4"
                      type="submit"
                    >
                      ส่งข้อความ
                    </ButtonRoundedDefault>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center container mx-auto mb-xl-5">
        <h2>CONTACT US</h2>
        <div className="px-xl-5 px-0">
          <ContactUsDefault otp={dataContactUs} />
          <GoogleMapDefault />
        </div>
      </div>

      <div className="button-line">
        <a
          className="d-block"
          href="https://line.me/ti/p/~@vgroup.th"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <span className="d-block">
            <Img
              fluid={data.line.childImageSharp.fluid}
              alt="line"
              loading="lazy"
            />
          </span>
        </a>
      </div>

      <footer className="text-center footer-bg-color">
        <p className="m-0">© 2012 - VBright Co.,Ltd</p>
      </footer>
    </Layout>
  )
}

export default Home

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "ns-v-bright-vision.webp" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "ns-v-bright-vision-explain.webp" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "v-wash-plus-vision.webp" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "v-wash-plus-vision-explain.webp" }) {
      ...fluidImage
    }
    line: file(relativePath: { eq: "logo-line.webp" }) {
      ...fluidImage
    }
    logo: file(relativePath: { eq: "logo-web.webp" }) {
      ...fluidImage
    }
    industrial: file(relativePath: { eq: "industrial.webp" }) {
      ...fluidImage
    }
  }
`
