import vBright1 from "../images/v-bright-lemon-3600-ml.webp"
import vBright2 from "../images/v-bright-lemon-700-ml.webp"
import vBright3 from "../images/v-bright-lemon-400-ml.webp"
import vBright4 from "../images/v-bright-lemon-150-ml.webp"
import vBright5 from "../images/v-bright-melon-3600-ml.webp"
import vBright6 from "../images/v-bright-melon-700-ml.webp"
import vBright7 from "../images/v-bright-melon-400-ml.webp"
import vBright8 from "../images/v-bright-melon-150-ml.webp"

export default [
  {
    id: 0,
    rec: "0",
    img: vBright1,
    alt: "วี-วอช พลัส แกลลอน กลิ่นมะนาว ขนาด 3,600 มล.",
    title: "วี-วอช พลัส แกลลอน กลิ่นมะนาว",
    content: "ขนาด 3,600 มล.",
    status: "1",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 4 แกลลอน",
    price: "ราคาขายปลีกต่อแกลลอน 100 บาท",
  },
  {
    id: 1,
    rec: "1",
    img: vBright2,
    alt: "วี-วอช พลัส ขวดบิ๊ก กลิ่นมะนาว ขนาด 700 มล.",
    title: "วี-วอช พลัส ขวดบิ๊ก กลิ่นมะนาว",
    content: "ขนาด 700 มล.",
    status: "1",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 12 ขวด",
    price: "ราคาขายปลีกต่อขวด 25 บาท",
  },
  {
    id: 2,
    rec: "0",
    img: vBright3,
    alt: "วี-วอช พลัส ถุงเติม กลิ่นมะนาว ขนาด 400 มล.",
    title: "วี-วอช พลัส ถุงเติม กลิ่นมะนาว",
    content: "ขนาด 400 มล.",
    status: "1",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 24 ถุง",
    price: "ราคาขายปลีกต่อถุง 12 บาท",
  },
  {
    id: 3,
    rec: "0",
    img: vBright4,
    alt: "วี-วอช พลัส ขวดมินิ กลิ่นมะนาว ขนาด 150 มล.",
    title: "วี-วอช พลัส ขวดมินิ กลิ่นมะนาว",
    content: "ขนาด 150 มล.",
    status: "1",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 72 ขวด",
    price: "ราคาขายปลีกต่อขวด 12 บาท",
  },
  {
    id: 4,
    rec: "0",
    img: vBright5,
    alt: "วี-วอช พลัส แกลลอน กลิ่นเมล่อน ขนาด 3,600 มล.",
    title: "วี-วอช พลัส แกลลอน กลิ่นเมล่อน",
    content: "ขนาด 3,600 มล.",
    status: "2",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 4 แกลลอน",
    price: "ราคาขายปลีกต่อแกลลอน 100 บาท",
  },

  {
    id: 5,
    rec: "1",
    img: vBright6,
    alt: "วี-วอช พลัส ขวดบิ๊ก กลิ่นเมล่อน ขนาด 700 มล.",
    title: "วี-วอช พลัส ขวดบิ๊ก กลิ่นเมล่อน",
    content: "ขนาด 700 มล.",
    status: "2",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 12 ขวด",
    price: "ราคาขายปลีกต่อขวด 25 บาท",
  },

  {
    id: 6,
    rec: "0",
    img: vBright7,
    alt: "วี-วอช พลัส ถุงเติม กลิ่นเมล่อน ขนาด 400 มล.",
    title: "วี-วอช พลัส ถุงเติม กลิ่นเมล่อน",
    content: "ขนาด 400 มล.",
    status: "2",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 24 ถุง",
    price: "ราคาขายปลีกต่อถุง 10 บาท",
  },
  {
    id: 7,
    rec: "0",
    img: vBright8,
    alt: "วี-วอช พลัส ขวดมินิ กลิ่นเมล่อน ขนาด 150 มล.",
    title: "วี-วอช พลัส ขวดมินิ กลิ่นเมล่อน",
    content: "ขนาด 150 มล.",
    status: "2",
    type: "2",
    total: "จำนวนบรรจุต่อลัง 72 ถุง",
    price: "ราคาขายปลีกต่อถุง 12 บาท",
  },
]
