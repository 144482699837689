import slide1 from "../images/ns-v-bright-slide.webp"
import slide2 from "../images/v-wash-plus-slide.webp"

export default [
  {
    id: 1,
    img: slide1,
    alt:'วี-ไบรท์ ฟลาวเวอร์'
  },
  {
    id: 2,
    img: slide2,
    alt:'วี-วอช พลัส'
  },
]
