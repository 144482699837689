import React from "react"
import "./style.scss"

const TextField = ({
  label,
  value,
  type,
  name,
  rows,
  onChange,
  onKeyDown,
  disable,
  maxLength,
  haveError,
  touched,
  desc,
  errors,
  detail,
  className,
  placeholder,
}) => {
  const showFeedback = (!!haveError && value.length > 2) || touched
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <input
        className={
          `input-default mt-2 ${
            showFeedback ? (errors ? "is-invalid input-err" : "") : ""
          }` + className
        }
        type={type}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        disabled={disable ? "disabled" : ""}
        maxLength={maxLength}
        rows={rows}
        onChange={event => {
          if (onChange) {
            onChange(event)
          }
        }}
        onKeyDown={event => {
          if (onKeyDown) {
            onKeyDown(event)
          }
        }}
      />
      {desc ? <p className="text-description m-0">{desc}</p> : ""}
      {showFeedback === true ? (
        <p className={`errText text-left m-0`}>
          {errors === "phone must be a valid phone number."
            ? "Invalid Phone Number!"
            : errors}
        </p>
      ) : (
        ""
      )}
      {detail && <p className="text-secondary pt-1">{detail}</p>}
    </div>
  )
}

export default TextField
