import React from "react"
import styled from "styled-components"

const ButtonRoundedStyle = styled.button`
  color: #fff;
  border-radius: 100px;
  border-width: 1px;
  background-color: #56b847;
  border-color: #56b847;
  padding: 7px 30px;
  border-radius: 100px;

  &:active,
  &:focus {
    background: #56b847;
    color: #ffffff;
    border: 2px solid #56b847;
    text-transform: capitalize;
  }
  &:hover {
    background: #ffffff;
    color: #56b847;
    border: 2px solid #56b847;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background: #56b847;
    color: #ffffff;
    border: 2px solid #56b847;
  }
`

const ButtonRounded = ({
  className,
  children,
  onClick,
  btnTitle,
  disabled,
  type,
}) => {
  return (
    <ButtonRoundedStyle
      aria-label={btnTitle}
      className={`btn btn-secondary  ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </ButtonRoundedStyle>
  )
}

export default ButtonRounded
