export default [
    {
      value: "0",
      label: "ทุกกลิ่น",
    },
    {
      value: "1",
      label: "กลิ่นมะนาว",
    },
    {
      value: "2",
      label: "กลิ่นเมล่อน",
    },
  ]