import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import ButtonRounded from "../ButtonRounded"
import "./style.scss"

const HeaderSlide = ({
  settings,
  dataProduct,
  width = "100%",
  height = "100%",
}) => {
  return (
    <>
      <Slider {...settings}>
        {dataProduct.map(item => {
          return (
            <div key={item.id}>
              <div
                className="product-item"
                style={{
                  backgroundImage: `url(${item.bg})`,
                  width: width,
                  height: height,
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="container d-flex justify-content-center justify-content-xl-end mx-auto">
                  <div className="row">
                    <div className="col-xl-5 product-text">
                      <div className="div-font mb-3 mb-xl-0">
                        <h1>{item.name}</h1>
                        <div className="div-btn">
                          <form action="https://line.me/ti/p/~@vgroup.th">
                            <ButtonRounded
                              className="font-size-20 mt-4 d-none d-xl-block px-5 py-2"
                              type="submit"
                            >
                              ซื้อเลย!
                            </ButtonRounded>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 product-img text-center text-xl-left">
                      <div className="img-pop text-center">
                        <img src={item.img} alt={item.alt} loading="lazy"/>
                      </div>
                      <div className="text-center">
                        <form action="https://line.me/ti/p/~@vgroup.th">
                          <ButtonRounded
                            className="font-size-18 mt-md-4 mt-1 mx-auto d-block d-xl-none px-5 py-2"
                            type="submit"
                          >
                            ซื้อเลย!
                          </ButtonRounded>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-bg" />
              </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

export default HeaderSlide
