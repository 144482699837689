import { FaFacebookF } from "react-icons/fa"
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md"
import React from "react"

export default [
    {
      id: "1",
      icon: <MdPhone className="icon-size" />,
      name: "PHONE",
      content: "098-3547909",
    },
    {
      id: "2",
      icon: <MdEmail className="icon-size" />,
      name: "EMAIL",
      content: "ss-inter@hotmail.com",
    },
    {
      id: "3",
      icon: <MdLocationOn className="icon-size" />,
      name: "LOCATION",
      content: "โครงการไซน่าทาวน์ศาลายา เลขที่ 111/50 หมู่ที่ 4 ต.ศาลายา อ.พุทธมณฑล จ.นครปฐม 73170",
    },
    {
      id: "4",
      icon: <FaFacebookF className="icon-size" />,
      name: "FACEBOOK",
      content: "ssi-group",
    },
  ]