import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const SlideDefault = ({ settings, dataImg, className }) => {
  return (
    <div className={className}>
      <Slider {...settings}>
        {dataImg.map(item => (
          <div key={item.id}>
            <img src={item.img} alt={item.alt} loading="lazy"/>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default SlideDefault
