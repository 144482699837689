export default [
    {
      value: "0",
      label: "ทุกสูตร",
    },
    {
      value: "1",
      label: "สูตรเข้มข้น",
    },
    {
      value: "2",
      label: "สูตรมาตรฐาน",
    },
  ]